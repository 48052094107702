@font-face {
    font-family: "Sansation";
    src: url("/public/fonts/Sansation%20Regular%20400.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Radikal";
    src: url("/public/fonts/Nootype%20-%20Radikal_7.otf") format("opentype"),
         url("/public/fonts/Nootype%20-%20Radikal%20Medium_1.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  