*{padding:0;margin:0;box-sizing:border-box}.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}.blur-background{position:relative;filter:blur(5px)}.thank-you-container{position:fixed;top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center;z-index:1000}.thank-you-content{text-align:center;font-size:1.5rem;color:green;font-family:Radikal;background-color:#fff;padding:20px;border:2px solid #daa520}.thank-you-ok-btn{margin-top:20px;background-color:green;font-size:1rem!important;color:#000!important;font-family:Radikal!important;background:var(--gradient-background)}@media (prefers-reduced-motion:no-preference){.App-logo{animation:20s linear infinite App-logo-spin}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}:root{--gradient-background:linear-gradient(
  156.91deg,
  rgba(148, 94, 37, 1) 0%,
  rgba(190, 141, 50, 1) 8.76%,
  rgba(244, 226, 145, 1) 34.53%,
  rgba(235, 212, 129, 1) 39.21%,
  rgba(212, 175, 88, 1) 48.28%,
  rgba(190, 141, 50, 1) 55.66%,
  rgba(243, 210, 122, 1) 65.17%,
  rgba(244, 214, 133, 1) 67.83%,
  rgba(247, 224, 163, 1) 72.55%,
  rgba(251, 240, 212, 1) 78.73%,
  rgba(255, 255, 255, 1) 83.65%,
  rgba(254, 253, 250, 1) 84.68%,
  rgba(252, 247, 236, 1) 85.86%,
  rgba(249, 238, 213, 1) 87.12%,
  rgba(244, 225, 181, 1) 88.43%,
  rgba(237, 209, 140, 1) 89.77%,
  rgba(235, 202, 124, 1) 90.24%,
  rgba(226, 192, 116, 1) 91.79%,
  rgba(201, 164, 94, 1) 94.43%,
  rgba(162, 120, 60, 1) 97.84%,
  rgba(134, 88, 35, 1) 100%
);--gradient-button-backgound:linear-gradient(
  131.03deg,
  rgba(148, 94, 37, 1) 0%,
  rgba(190, 141, 50, 1) 8.76%,
  rgba(244, 226, 145, 1) 34.53%,
  rgba(235, 212, 129, 1) 39.21%,
  rgba(212, 175, 88, 1) 48.28%,
  rgba(190, 141, 50, 1) 55.66%,
  rgba(243, 210, 122, 1) 65.17%,
  rgba(235, 199, 111, 1) 69.48%,
  rgba(213, 171, 81, 1) 77.07%,
  rgba(190, 141, 50, 1) 83.65%,
  rgba(193, 145, 55, 1) 85.07%,
  rgba(201, 156, 69, 1) 86.7%,
  rgba(215, 175, 92, 1) 88.43%,
  rgba(235, 202, 123, 1) 90.22%,
  rgba(235, 202, 124, 1) 90.24%,
  rgba(226, 192, 116, 1) 91.79%,
  rgba(201, 164, 94, 1) 94.43%,
  rgba(162, 120, 60, 1) 97.84%,
  rgba(134, 88, 35, 1) 100%
)}@keyframes App-logo-spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}@media (min-width:2000px){.root{max-width:2000px;margin:0 auto}}.MuiContainer-root{max-width:none!important}