

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Radikal','Sansation_Bold_23','Sansation_Regular_3F',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@media (min-width: 2000px) {
  .root {
    margin: 0 calc((100vw - 1200px) / 2);
  }
}